import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import Features from "../components/Features";

// eslint-disable-next-line
export const ADokPageTemplate = ({ title, description, pealkiri, alaPealkiri, content, contentComponent, intro, introPealkiri, introTutvustusTekst }) => {

  const PageContent = contentComponent || Content;

  return (
    <>
      <Helmet
        title={title}
        meta={[
          { name: 'description', content: description }
        ]}
      >
      </Helmet>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{pealkiri}</h2>
            <p>{alaPealkiri}</p>
          </div>
        </header>
        <div className="wrapper">
          <div className="inner">
            <PageContent className="content" content={content} />
            <Features gridItems={intro.blurbs} />
            <h3>{introPealkiri}</h3>
            <p>{introTutvustusTekst}</p>
          </div>
        </div>
      </section>
    </>
  );
};


ADokPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  pealkiri: PropTypes.string.isRequired,
  alaPealkiri: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  introPealkiri: PropTypes.string,
  introTutvustusTekst: PropTypes.string,
};


const ADokPage = () => {
  const data = useStaticQuery(graphql`
  query ADokPage {
  markdownRemark(frontmatter: {templateKey: {eq: "a-dok-page"}}) {
    frontmatter {
      intro {
        blurbs {
          heading
          text
          image
        }
      }
      templateKey
      description
      alaPealkiri
      pealkiri
      title
      introPealkiri
      introTutvustusTekst
    }
    html
  }
    allImageSharp {
    nodes {
      gatsbyImageData(
        quality: 100
        width: 640
        height: 427
        layout: CONSTRAINED
        placeholder: BLURRED
      )
    }
  }
}
`)

  const images = data.allImageSharp.nodes;
  const introBlurbs = data.markdownRemark.frontmatter.intro.blurbs;

  const imagesArray = [];
  images.forEach(image => {
    imagesArray.push(image.gatsbyImageData.images.fallback.src);
  });
  introBlurbs.forEach(blurb => {
    if (typeof blurb.image === 'string') {
      let string = blurb.image;
      let result = string.substring(string.lastIndexOf("/") + 1);
      images.forEach(image => {
        if (image.gatsbyImageData.images.fallback.src.includes(result)) {
          blurb.image = image
        }
      });
    }
  });

  return (
    <Layout fullMenu>
      <ADokPageTemplate
        contentComponent={HTMLContent}
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        content={data.markdownRemark.html}
        pealkiri={data.markdownRemark.frontmatter.pealkiri}
        alaPealkiri={data.markdownRemark.frontmatter.alaPealkiri}
        intro={data.markdownRemark.frontmatter.intro}
        introPealkiri={data.markdownRemark.frontmatter.introPealkiri}
        introTutvustusTekst={data.markdownRemark.frontmatter.introTutvustusTekst}
      />
    </Layout>
  );
};


ADokPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ADokPage;